import React from 'react';
import { Button, parseSearch, useToast } from '@seccl/ui';
import type { GetServerSidePropsContext, InferGetServerSidePropsType, NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getProtectedServerSession } from '../../api/serverSession';
import { Layout } from '../../components/layout/layout';
import { routes } from '../../routes';
import { isValidReturnPath } from '../../utils/returnPath';

import styles from './signin.module.scss';

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const protectedSession = await getProtectedServerSession(context);

  const decodedUrl = decodeURIComponent(context.resolvedUrl);
  const { returnUrl } = parseSearch<{ returnUrl: string }>(decodedUrl);

  const destination = returnUrl && isValidReturnPath(returnUrl) ? returnUrl : routes.dashboard;

  if (protectedSession.type === 'AUTHENTICATED') {
    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }
  return {
    props: {
      returnUrl: destination,
      branding: protectedSession.branding,
    },
  };
};

const SignInPage: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({ branding, returnUrl }) => {
  const router = useRouter();
  const errorFromQuery = router.query.error;

  const { dispatchToast, closeToast } = useToast();

  React.useEffect(() => {
    let toastId: number | string;
    if (errorFromQuery) {
      // Dispatch a toast to show the error message
      toastId = dispatchToast({
        variant: 'error',
        title: 'Authentication error',
        message: errorFromQuery as string,
      });

      // Remove error from query
      router.replace(routes.auth.signIn(), undefined, { shallow: true });
    }

    return () => closeToast(toastId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Sign in">
      <div className={styles.container}>
        <section className={styles.signin}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {branding?.logoUrl && <img className={styles.logo} src={branding?.logoUrl} alt="Logo" />}
          <h1>Welcome</h1>
          <p>Click the button below to sign in</p>
          <Button LinkComponent={Link} className={styles.button} href={routes.auth.auth0.login(returnUrl)}>
            Sign in
          </Button>
        </section>
      </div>
    </Layout>
  );
};

export default SignInPage;
